import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Contact from "../../components/contact"
import OfferLayout from "../../components/offerLayout"
import list from "../../content/oferta/szczotki.yaml"
import image from "../../images/oferta/szczotki.jpeg"

const OfertaSzczotki = () => (
  <Layout>
    <Seo title="Oferta" />
    <div className="container py-20">
      <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Oferta</h1>
      <div className="border-b-8 border-secondary w-12 mb-12"></div>
        <OfferLayout title="Sczotki" image={image} list={list}>
        </OfferLayout>
    </div>
    <Contact greyBackground={true} />
  </Layout>
)

export default OfertaSzczotki
